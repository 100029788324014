import React, { useRef, useEffect, useState, useCallback,useLayoutEffect } from "react";
import Header from "../components/Header";
import Style from "@/assets/css/home.module.scss";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import Footer from "@/components/Footer";
import config from "@/utils/config";

const Index = () => {
    const bannerRef = useRef();
    const [downloadList, setDownloadList] = useState([]);
    const [checkIndex, setCheckIndex] = useState(null);
    const [topArr, setTopArr] = useState([]);

    const bannerList = [
        require("@/assets/images/banner1.jpeg").default,
        require("@/assets/images/banner2.jpeg").default,
        require("@/assets/images/banner3.jpeg").default,
    ];

    const benefitList = [
        {
            numImg: require("@/assets/images/1.png").default,
            img: require("@/assets/images/img1.png").default,
            text: "Afternoon Tea",
        },
        {
            numImg: require("@/assets/images/2.png").default,
            img: require("@/assets/images/img2.png").default,
            text: "Holiday Benefits",
        },
        {
            numImg: require("@/assets/images/3.png").default,
            img: require("@/assets/images/img3.png").default,
            text: "Company Trip",
        },
        {
            numImg: require("@/assets/images/4.png").default,
            img: require("@/assets/images/img4.png").default,
            text: "Food Allowance",
        },
        {
            numImg: require("@/assets/images/5.png").default,
            img: require("@/assets/images/img5.png").default,
            text: "Annual Bonus",
        },
        {
            numImg: require("@/assets/images/6.png").default,
            img: require("@/assets/images/img6.png").default,
            text: "Taxi Reimbursement",
        },
    ];

    let handleScroll = (topArr)=>{
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;

        let index = null;
        for (let i = 0; i < topArr.length; i++) {
            if (scrollTop > topArr[i]) {
                index = i;
            }
        }
        setCheckIndex(index);
    };

const getOffsetTop = (element) => {

    let actualTop = element.offsetTop;

    let current = element.offsetParent;

    while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
    }

    return actualTop;

}

    useLayoutEffect(()=>{
        let checkList = ["game", "about", "contact"];
        let topArr = [];
        for (let i = 0; i < checkList.length; i++) {
            let ele = document.querySelector(`#${checkList[i]}`);

            topArr.push( getOffsetTop(ele)-2);
        }
        setTopArr(topArr);
        console.log(topArr,'topArr');

        window.addEventListener("scroll", ()=>handleScroll(topArr), false);
        return () => {
            window.removeEventListener("scroll", ()=>handleScroll(topArr), false);
        };
    },[])

    useEffect(() => {
        if (bannerRef.current) {
            var swiper = new Swiper(bannerRef.current, {
                speed: 2000,
                effect: "fade",
                autoplay: {},
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
        }

        let arr = [];
        let apple = {
            imgSrc: require("@/assets/images/appStore.png").default,
            url: config.appStoreUrl,
        };
        let android = {
            imgSrc: require("@/assets/images/googlePlay.png").default,
            url: config.googlePlayUrl,
        };
        if (config.googlePlayUrl) {
            arr.push(android);
        }
        if (config.appStoreUrl) {
            arr.push(apple);
        }
        setDownloadList(arr);




    }, []);

    return (
        <div className="page home-page pt102">
            <Header checkIndex={checkIndex} />
            <div className="content">
                <div className="swiper-banner relative">
                    <div className="banner-text">
                        <img
                            src={
                                require("@/assets/images/banner-text.png")
                                    .default
                            }
                            alt=""
                        />
                    </div>
                    <div
                        className="swiper swiper-container swiper-container-fade"
                        ref={bannerRef}
                    >
                        <div className="swiper-wrapper">
                            {bannerList.map((banner, index) => {
                                return (
                                    <div
                                        className="mv__item swiper-slide"
                                        key={index}
                                    >
                                        <img src={banner} />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>

                <div id="game"></div>
                <div className="game pt84 pb95">
                    <div className="game-title">
                        <img
                            src={
                                require("@/assets/images/game-title.png")
                                    .default
                            }
                            alt=""
                        />
                    </div>
                    <div className="download flex justify-center">
                        {downloadList.map((item, index) => {
                            return (
                                <div
                                    onClick={() => window.open(item.url)}
                                    className="download-item ml18 mr18"
                                    key={index}
                                >
                                    <img src={item.imgSrc} alt="" />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div id="about"></div>
                <div className="about game pt84 pb110">
                    <div className="game-title">
                        <img
                            src={
                                require("@/assets/images/about-title.png")
                                    .default
                            }
                            alt=""
                        />
                    </div>
                    <div className="message f-cb">
                        <div className="message-img">
                            <img
                                src={
                                    require("@/assets/images/message-img.jpeg")
                                        .default
                                }
                                alt=""
                            />
                        </div>

                        <div className="message-cont pb57">
                            <img
                                src={
                                    require("@/assets/images/arrow-top.png")
                                        .default
                                }
                                alt=""
                                className="arrow-img"
                            />
                            <div className="text">
                                With the mission of "creating value for users",
                                the company is committed to the development and
                                operation of overseas Internet cultural and
                                creative products. With the core value of
                                "creating value for our partners" and the
                                enterprise spirit of "honesty, tolerance,
                                innovation and service", we create value for the
                                Internet and information service industry
                                through independent innovation and sincere
                                cooperation.
                            </div>
                        </div>
                    </div>

                    <div className="w1200 company mt160">
                        <img
                            className="title"
                            src={require("@/assets/images/company.png").default}
                            alt=""
                        />
                        <div className="benefit flex justify-between flex-wrap">
                            {benefitList.map((item) => {
                                return (
                                    <div
                                        className="benefit-item"
                                        key={item.img}
                                    >
                                        <div className="top-box">
                                            <div className="num">
                                                <img src={item.numImg} alt="" />
                                            </div>
                                            <div className="img">
                                                <img src={item.img} alt="" />
                                            </div>
                                        </div>
                                        <div className="intro">{item.text}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div id="contact"></div>
                <div className="contact game pt84 pb265">
                    <div className="game-title">
                        <img
                            src={require("@/assets/images/contact.png").default}
                            alt=""
                        />
                    </div>
                    <div className="w1200 flex contact-msg justify-between">
                        <div className="flex-item address">
                            <div className="title">OFFICE ADDRESS</div>
                            <div className="cont">
                                60 PAYA LEBAR ROAD #08-10 <br />
                                PAYA LEBAR SQUARE <br />
                                SINGAPORE 409051
                            </div>
                        </div>
                        <div className="flex-item email">
                            <div className="title">CONTACT EMAIL</div>
                            <div className="cont">tapfun.ltd@outlook.com</div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Index;
